import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useWebAppConfigContext } from '../context'
import { TranslationCodeLiteral, WebAppConfig } from '../models'
import { useLanguage } from './useLanguage'

type CustomTranslationPropertyName =
  | 'welcomeMessage'
  | 'welcomeGreeting'
  | 'endCheckMessage'
  | 'termsAndConditionsExplanatoryText'

export const useI18n = () => {
  const { currentLanguage } = useLanguage()
  const { webAppConfig } = useWebAppConfigContext()
  const { t } = useTranslation()

  const i18n = useCallback(
    (code: TranslationCodeLiteral | '', params?: Record<string, string | undefined>) => t(code, { ...params }),
    [t],
  )

  const getCustomTranslationWithLocalFallback = useCallback(
    (customTranslationPropertyName: keyof Pick<WebAppConfig, CustomTranslationPropertyName>) =>
      webAppConfig?.[customTranslationPropertyName]?.[currentLanguage] || t(customTranslationPropertyName),
    [webAppConfig, currentLanguage, t],
  )

  return {
    i18n,
    getCustomTranslationWithLocalFallback,
  }
}

import React from 'react'

export const replaceBoldTags = (input: string) => {
  const regex = /<b>(.*?)<\/b>/g
  const parts = []
  let lastIndex = 0
  let match

  while ((match = regex.exec(input)) !== null) {
    const [fullMatch, p1] = match
    const offset = match.index

    if (offset > lastIndex) {
      parts.push(input.slice(lastIndex, offset))
    }

    parts.push(<b key={offset}>{p1}</b>)

    lastIndex = offset + fullMatch.length
  }

  if (lastIndex < input.length) {
    parts.push(input.slice(lastIndex))
  }

  return parts
}

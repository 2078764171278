import { useCallback } from 'react'

export const useCheckIdStorage = () => {
  const setStoredCheckId = useCallback((checkId: string) => {
    localStorage.setItem('checkID', checkId)
  }, [])

  const removeStoredCheckId = useCallback(() => {
    localStorage.removeItem('checkID')
  }, [])

  return { setStoredCheckId, removeStoredCheckId }
}

import React, { useMemo } from 'react'
import { LoaderLayer } from '../LoaderLayer'
import { MainPage } from './MainPage/MainPage'
import { XundConfigCatProvider } from 'xund-configcat-react'
import { useQueryParams } from 'xund-react-utils'
import { useParams } from 'react-router'

/**
 * The application screen component
 *
 * @returns The application screen component
 */
export const AppScreen = () => {
  const clientId = useQueryParams().get('clientId')
  const { code: webappCode } = useParams<{ code: string }>()

  const configCatOptions = useMemo(
    () => ({
      sdkKey: window.xundEnvironment?.CONFIGCAT_SDK_KEY,
      user: {
        custom: {
          context: window.xundEnvironment?.CONFIGCAT_CONTEXT,
          clientId,
          webappCode,
        },
      },
    }),
    [clientId, webappCode],
  )

  return (
    <LoaderLayer>
      <XundConfigCatProvider {...configCatOptions}>
        <MainPage />
      </XundConfigCatProvider>
    </LoaderLayer>
  )
}
